export const GA_TRACKING_ID = "G-8CM1R4LRGF" //replace it with your measurement id

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url:string) => {
    // @ts-ignore
  window.gtag("config", GA_TRACKING_ID, {
    page_path: url,
  })
}
type actionType =
  "purchase" |
  "begin_checkout" |
  "add_shipping_info" |
  "add_payment_info"|
  "view_cart"|
  "view_item"|
  "payment_failed"|
  "add_to_cart"|
  "payment_already_paid"


type eventType={
    action: actionType
    category: string
    label: string
    value: string
}
type purchaseEventType={
  price:number
  tax?: number
  transaction_id: string
  eventId:string
  eventName:string
  action: actionType
}
export const purchaseEvent = ({action,eventId, price, eventName, tax, transaction_id}:purchaseEventType) =>{
  /* @ts-ignore*/
  window.gtag("event", action, {
    currency: "SAR",
    value: price,
    transaction_id: transaction_id,
    tax: tax,
    items: [
      {
       item_id: eventId,
       item_name: eventName,
       price: price,
       quantity: 1
     }
    ]
  })
}


//https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#select_item
export const event = ({ action, category, label, value }:eventType) => {
    // @ts-ignore
  window.gtag("event", action, {
    value: value,
    event_category: category,
    event_label: label,
  })
}