
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import '../styles/globals.css'
import '@smastrom/react-rating/style.css'
import type { AppProps } from 'next/app'
import { SessionProvider } from 'next-auth/react'
import {
  MantineProvider,
  ColorSchemeProvider,
  ColorScheme,
} from '@mantine/core'
import Head from 'next/head'
import React, { FC, useEffect } from 'react'
//import { initializeApp } from 'firebase/app'
import NextNProgress from 'nextjs-progressbar'
import useTranslation from 'next-translate/useTranslation'
import { ApolloProvider } from '@apollo/client'
import { NotificationsProvider } from '@mantine/notifications'
import { useColorScheme, useLocalStorage } from '@mantine/hooks'
import { ModalsProvider } from '@mantine/modals'
import rtlPlugin from 'stylis-plugin-rtl'
import { useApollo } from '../lib/apolloClient'
import Script from 'next/script' 
import { useRouter } from 'next/router'
import * as gtag from '@/util/gtag'

//import localforage from 'localforage'
//import { getMessaging, getToken, isSupported } from 'firebase/messaging'
//import firebaseApp, { requestToken } from '@/util/firebase'

const Noop: FC<{ children: React.ReactNode }> = ({ children }) => (
  <div>{children}</div>
)

const App = ({ Component, pageProps: { session, ...pageProps } }: AppProps) => {
  const preferredColorScheme = useColorScheme()
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: 'mantine-color-scheme',
    defaultValue: preferredColorScheme,
    getInitialValueInEffect: true,
  })
  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'))

  const Layout = (Component as any).Layout ?? Noop
  const withGlobalStyles = (Component as any).withGlobalStyles ?? true // disabled for schedule page, overlapping when downloading image

  const { lang, t } = useTranslation('common')
  const isArabic = lang === 'ar'

  const client: any = useApollo(pageProps.initialApolloState)
  const router = useRouter()
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      gtag.pageview(url)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])
  /*useEffect(() => {
    requestToken()
  }, [])
  async function requestToken() {
    if (!await isSupported()) return

    try {
      const messaging = getMessaging(firebaseApp);
      const tokenInLocalForage = await localforage.getItem('fcm_token')

      // Return the token if it is alredy in our local storage
      if (tokenInLocalForage !== null) {
        return tokenInLocalForage
      }

      // Request the push notification permission from browser
      const status = await Notification.requestPermission()
      if (status && status === 'granted') {
        // Get new token from Firebase
        const fcmToken = await getToken(messaging, {
          vapidKey:
            'BAeUYSiI6oBZFFKIzbzRzabNZ9wuntik0goxQDmVzp_isZdEf6x9ClGUvpbwKAv81UtzMRXyzPRyW5NILlaW0Lc',
        })

        // Set token in our local storage
        if (fcmToken) {
          console.log('token _app:  ', fcmToken)
          // Send the token to your server
          await localforage.setItem('fcm_token', fcmToken)
          return fcmToken
        }
      }
    } catch (error) {
      console.error(error)
      return null
    }
  } */ 
  return (
    <SessionProvider session={session} refetchInterval={5*60*60} refetchOnWindowFocus={false}>
      <ApolloProvider client={client}>
        <NextNProgress options={{ showSpinner: false, color: '#3adff5' }} />
        <Script
          strategy="afterInteractive"
          src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
        />

        <Script id="gtag-init" strategy="afterInteractive">
          {process.env.NODE_ENV === 'development'
            ? `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${gtag.GA_TRACKING_ID}', {
                    page_path: window.location.pathname,'debug_mode': true
                    });
                `
            : `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${gtag.GA_TRACKING_ID}', {
                    page_path: window.location.pathname
                    });
                `}
        </Script>
        <Head>
          <title>{t('appName')}</title>
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width"
          />
          	<link rel="manifest" href="/manifest.json" />
        </Head>
        <ColorSchemeProvider
          colorScheme={colorScheme}
          toggleColorScheme={toggleColorScheme}
        >
          <MantineProvider
            withGlobalStyles={withGlobalStyles}
            withNormalizeCSS
            emotionOptions={
              isArabic
                ? { key: 'rtl', stylisPlugins: [rtlPlugin] }
                : { key: 'mantine' }
            }
            theme={{
              dir: isArabic ? 'rtl' : 'ltr',
              colorScheme,
              primaryColor: 'cyan',
              defaultRadius: 5,
              fontFamily: 'Cairo, sans-serif',
            }}
            styles={{
              Table: theme => ({
                root: {
                  backgroundColor: theme.fn.rgba(
                    theme.colors[theme.primaryColor]![5],
                    0.05,
                  ),
                  borderRadius: theme.defaultRadius,
                  overflow: 'hidden',
                  boxShadow: theme.shadows.md,
                },
              }),
            }}
          >
            <ModalsProvider>
              <NotificationsProvider zIndex={2077}>
                <main dir={isArabic ? 'rtl' : 'ltr'}>
                  <Layout>
                    <Component {...pageProps} />
                  </Layout>
                </main>
              </NotificationsProvider>
            </ModalsProvider>
          </MantineProvider>
        </ColorSchemeProvider>
      </ApolloProvider>
    </SessionProvider>
  )
}

const __Page_Next_Translate__ = App


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  