/* eslint-disable */ 
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloLink,
  NormalizedCacheObject,
} from '@apollo/client'
import { useMemo } from 'react'
import { onError } from "@apollo/client/link/error";

let apolloClient: ApolloClient<NormalizedCacheObject>

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});
// parses the cookies string and returns an object with key/value pairs
export const getCookies = (cookie: String) =>
  cookie.split('; ').reduce((prev, current) => {
    const [name, ...value] = current.split('=')
    // eslint-disable-next-line no-param-reassign
    ;(prev as any)[name as string] = value.join('=')
    return prev
  }, {}) as { [key: string]: any }

function createApolloClient(nextAuthSessionToken?: string) {
  const ssrMode = typeof window === 'undefined'
  console.log('ssr: ', process.env.NEXT_PUBLIC_API_SSR)
  const uri =
    (ssrMode
      ? process.env.NEXT_PUBLIC_API_SSR || process.env.NEXT_PUBLIC_API
      : process.env.NEXT_PUBLIC_API) || 'https://graphql.infinity.edu.sa'
    
  let authorization
  if (typeof document !== 'undefined') {
    const cookies = getCookies(document.cookie)
    const token = cookies['next-auth.session-token']
    authorization = token ? `Bearer ${token}` : undefined
  }

  if (nextAuthSessionToken) {
    authorization = `Bearer ${nextAuthSessionToken}`
  }

  // eslint-disable-next-line no-console
  console.log('client connecting to :', uri)
  const httpLink =
    //  createPersistedQueryLink({ sha256 }).concat(
    createHttpLink({
      uri,
      credentials: 'include',
      headers: {
        authorization,
      },
    })
  // )
  const link = ApolloLink.from([
    errorLink,
    httpLink,
  ]);
  return new ApolloClient({
    ssrMode,
    link,
    cache: new InMemoryCache(),
  })
}

export function initializeApollo(
  initialState = null,
  nextAuthSessionToken?: string,
) {
  const _apolloClient = apolloClient ?? createApolloClient(nextAuthSessionToken)

  if (initialState) {
    _apolloClient.cache.restore(initialState)
  }
  if (typeof window === 'undefined') return _apolloClient
  if (!apolloClient) apolloClient = _apolloClient

  return _apolloClient
}

export function useApollo(initialState: any) {
  return useMemo(() => initializeApollo(initialState), [initialState])
}